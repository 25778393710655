<template>
    <div class="card">
        <div class="card-body">
            <template v-if="!loadingCoupon && coupon">
                <div class="box-share">
                    <div class="icon">
                        <feather type="command" size="120" />
                    </div>
                    <div class="name">
                        {{ coupon.name }}
                    </div>
                    <div class="action">
                        <button class="btn btn-primary" :disabled="loadingDuplicate" @click="duplicate">
                            <b-spinner v-if="loadingDuplicate" small /> <i class="uil uil-plus"></i> Add To Campaign
                        </button>
                        <button class="btn btn-dark ml-2" @click="forget">
                            Forget
                        </button>
                    </div>
                </div>
            </template>
            <div v-else class="text-center">
                <b-spinner></b-spinner>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loadingCoupon: false,
                coupon: null,
                loadingDuplicate: false,
            }
        },

        mounted() {

            this.getCoupon()
        },

        methods: {
            getCoupon() {
                this.loadingCoupon = true
                this.$store
                    .dispatch('coupon/findByUniqueId', this.$route.params.id)
                    .then((coupon) => {
                        this.loadingCoupon = false
                        this.coupon = coupon
                    })
                    .catch(() => {
                        this.loadingCoupon = false
                        this.$router.push({ name: 'business.coupons.index' })
                    })
            },

            forget() {
                this.$router.push({ name: 'business.coupons.index' })
            },

            duplicate() {
                this.loadingDuplicate = true
                this.$store
                    .dispatch('coupon/duplicate', this.$route.params.id)
                    .then((res) => {
                        this.loadingDuplicate = false
                        if (res.type === 'popup')
                            this.$router.push({ name: 'business.coupons.edit', params: {id: res.id} })
                        else
                            this.$router.push({ name: 'business.landings.edit', params: {id: res.id} })
                    })
                    .catch(() => {
                        this.loadingDuplicate = false
                    })
            }
        },
    }
</script>

<style lang="scss" scoped>
.box-share {
    text-align: center;
    padding: 50px 0px;
    .icon {
        display: inline-block;
        background: #ff694c;
        color: #fff;
        border-radius: 50%;
        padding: 26px;
    }
    .name {
        color: #394a55;
        font-weight: bold;
        font-size: 24px;
        margin: 20px 0px;
    }
}

</style>